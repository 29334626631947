%iconfont {
  font-family: var(--font-iconfont) !important;
  font-size: 12px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  text-rendering: auto;
  display: inline-block;
}

$iconfont-angle-down: "\ea01";
$iconfont-angle-right: "\ea02";
$iconfont-angle-up: "\ea03";
$iconfont-caret-down: "\ea04";
$iconfont-caret-up: "\ea05";
$iconfont-checkmark: "\ea06";
$iconfont-info: "\ea07";
$iconfont-loader-progress: "\ea08";
$iconfont-model: "\ea09";
$iconfont-owner: "\ea0a";
$iconfont-search: "\ea0b";
$iconfont-user: "\ea0c";
$iconfont-warning: "\ea0d";
$iconfont-x: "\ea0e";
