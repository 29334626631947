@import "./styles";
@import "./iconfont";

:root {
	--clr-neutral-50: #{$clr-neutral-50};
	--clr-primary-500: #{$clr-primary-500};
	--clr-primary-700: #{$clr-primary-700};
	--clr-primary-800: #{$clr-primary-800};
}

html {
	overflow-y: scroll;
}

html,
body {
	padding: 0;
	margin: 0;
	background-color: $clr-primary-600;
	font-size: 14px;
	color: $clr-neutral-100;
	line-height: 1.5em;
	height: 100%;
	font-family: $font-family;
}

:root {
	--gutter: 72px;
	--slider-container-width: 21px;
	--slider-container-height: 10px;
	--slider-knob-width: 16px;
	--slider-knob-height: 16px;

	@media (max-width: 665px) {
		--gutter: 16px;
		--slider-container-width: 39px;
		--slider-container-height: 20px;
		--slider-knob-width: 24px;
		--slider-knob-height: 24px;
	}
}

* {
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

p {
	margin: 0;
	padding: 0;
}

a,
.link {
	text-decoration: none;
	color: $clr-secondary-500;
	cursor: pointer;

	@media (hover: hover) {
		&:hover {
			color: $clr-secondary-400;
		}
	}

	@media (hover: none) {
		&:active {
			color: $clr-secondary-400;
		}
	}
}

h1 {
	font-size: 18px;
	margin: 0 0 16px;
	text-align: center;
	color: $clr-neutral-50;
}

h2 {
	font-size: 16px;
	font-weight: normal;
	margin: 4px 0 16px;
	text-align: center;
	color: $clr-neutral-100;
}

/**
 * sticky footer
 */

#__next {
	height: 100%;
}

.main-container {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.main-content {
	flex: 1 0 auto;
	margin: 0;
}

/**
 * forms
 */

label {
	user-select: none;
}

button {
	appearance: none;
	background: none;
	border: none;
	border-radius: 0;
	padding: 0;
}

button:not([role=link]),
a.button {
	background: linear-gradient(180deg, $clr-secondary-500, $clr-secondary-600);
	border: 0 none;
	border-radius: 4px;
	color: $clr-exclusive-600;
	cursor: pointer;
	display: inline-block;
	font-weight: 700;
	padding: 0 24px;
	height: 42px;
	line-height: 42px;
	text-align: center;
	margin: 0 auto;
	font-size: 14px;
	text-decoration: none;
	width: fit-content;
	white-space: nowrap;

	&:hover {
		background: linear-gradient(180deg, $clr-secondary-400, $clr-secondary-600);
	}

	&.button-white-outline {
		background: none;
		color: $clr-neutral-50;
		border: 1px solid $clr-neutral-50;
		border-radius: 4px;
	}
}

button[disabled],
.button-disabled {
	@extend .button;
	opacity: .4;
	pointer-events: none;
	cursor: default;
}

.button-100 {
	width: 100%;

	.button {
		width: 100%;
	}
}

.button-50 {
	width: 100%;
	display: flex;
	gap: 8px;
	justify-content: stretch;

	button {
		width: 50%;
	}

	@media (max-width: 665px) {
		flex-direction: column;
		gap: 16px;

		button {
			width: 100%;
		}
	}
}

button[role=link] {
	appearance: none;
}

input[type='checkbox'] {
	position: relative;
	appearance: none;
	min-width: 24px;
	width: 24px;
	height: 24px;
	background: $clr-primary-900;
	box-shadow: 0 1px 0 $clr-neutral-400;
	border: 1px solid $clr-secondary-500;
	border-radius: 4px;
	line-height: 20px;
	cursor: pointer;
	color: transparent;
	text-align: center;
	vertical-align: middle;

	&::before {
		@extend %iconfont;
		content: unquote("\"#{ $iconfont-checkmark }\"");
		font-size: 11px;
		vertical-align: middle;
	}

	&:checked {
		color: $clr-secondary-500;
	}

	&[disabled] {
		opacity: 0.4;
		cursor: default;
	}
}

.checkbox-slider {
	display: none;

	& + label {
		cursor: pointer;
		margin: 3px 8px;
		display: inline-block;
		width: var(--slider-container-width);
		height: var(--slider-container-height);
		border-radius: calc(var(--slider-container-height) / 2);
		background: $clr-neutral-600;
		position: relative;
		transition: background .2s linear;

		&:before {
			content: "";
			display: block;
			position: absolute;
			width: var(--slider-knob-width);
			height: var(--slider-knob-height);
			border-radius: 50%;
			background: $clr-neutral-50;
			border: 1px solid $clr-neutral-300;
			box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
			top: calc((var(--slider-container-height) - var(--slider-knob-height)) / 2 - 1px);
			left: calc(var(--slider-knob-width) / 2 * -1);
			transition: left .2s linear;

			@media (max-width: 665px) {
				left: -2px;
			}
		}
	}

	&:checked + label {
		background: $clr-secondary-700;

		&:before {
			left: calc(var(--slider-container-width) - var(--slider-knob-width) / 2);

			@media (max-width: 665px) {
				left: calc(var(--slider-container-width) - var(--slider-knob-width) + 2px);
			}
		}
	}
}