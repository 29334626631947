@import "Blocks.module";
@import "styles";

.genericErrorBox {
	--gutter: 24px;
	@extend .genericBox;
	background-color: unset;
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: $clr-neutral-50;

	h1 {
		text-transform: uppercase;
		font-size: 20px;
	}

	p {
		margin-top: 0;
	}

	picture {
		display: inline-block;
		margin-bottom: 40px;
		text-align: center;
		@media (max-width: 665px) {
			img {
				width: auto;
				height: auto;
			}
		}
	}
	@media (max-width: 665px) {
		align-items: center;
		justify-content: center;
		height: 100%;
	}

}

.Picture404 {
	@media (max-width: 665px) {
		&, img {
			max-width: 255px;
		}
	}
}

.PictureLock {
	@media (max-width: 665px) {
		&, img {
			max-width: 160px;
		}
	}
}

.PictureMagnifyingGlass {
	@media (max-width: 665px) {
		&, img {
			max-width: 217px;
		}
	}
}