@import "styles";
@import "iconfont";

@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}

.genericBox {
	padding: 40px 0;
	width: 640px;
	margin: 40px auto 0;
	font-size: 16px;
	border-radius: 4px;
	background-color: $clr-primary-700;

	> h1, h2 {
		@extend .unifiedGutter;
	}

	> p {
		@extend .unifiedGutter;
		margin: {
			top: 24px;
			bottom: 24px;
		}
		text-align: center;
	}

	@media (max-width: 665px) {
		padding: 0;
		width: 100%;
		background: none;
	}
}

.genericBox2 {
	@extend .genericBox;

	h1 {
		font-size: 26px;
	}
}

.iconPanel {
	border-radius: 5px;
	border: 1px solid #000;
	color: #000;
	background-color: #fff;
	max-width: 25vw;
	padding: 15px;
	margin: 50px auto;
	display: block;
	text-align: center;

	h2 {
		padding: 0;
		margin: 0;
	}

	svg {
		height: 40px;
		margin: 25px;
	}

	p {
		font-size: .8rem;
		margin: 0;
	}
}

a.iconPanel {
	color: #000;

	&:link,
	&:visited,
	&:active, {
		color: #000;
	}

	&:hover {
		background-color: rgba(0, 0, 0, .01);
		border-color: #006ba5;
		box-shadow: 0 0 3px #006ba5;
	}
}

@mixin roundedBoxWithTitle {
	border: 1px solid transparent;
	font-size: 16px;
	border-radius: 4px;
	line-height: 1.2em;
	@extend .unifiedGutter;

	> div {
		padding: 16px;
	}

	> h3 {
		text-transform: uppercase;
		display: block;
		margin: 0;
		padding: 0;
		font-weight: normal;
		font-size: 16px;
		line-height: 32px;
		text-align: center;

		&::before {
			@extend %iconfont;
			margin-right: 6px;
			font-size: 20px;
			color: $clr-primary-900;
			vertical-align: text-bottom;
		}
	}

	ul {
		margin: 0;
		padding-inline-start: 20px;
	}

	p:not(:last-of-type) {
		margin-bottom: 1.5em;
	}
}

.warning {
	@include roundedBoxWithTitle;
	border-color: $clr-secondary-500;
	color: $clr-secondary-500;

	> h3 {
		background-color: $clr-secondary-500;
		color: $clr-primary-900;

		&::before {
			content: unquote("\"#{ $iconfont-warning }\"");
		}
	}
}

.info {
	@include roundedBoxWithTitle;
	border-color: $clr-primary-500;
	color: $clr-neutral-50;

	> h3 {
		background-color: $clr-primary-500;
		color: $clr-neutral-50;

		&::before {
			color: $clr-neutral-50;
			content: unquote("\"#{ $iconfont-info }\"");
		}
	}
}

@mixin checkboxBlockBase {
	background-color: $clr-primary-700;
	border: 0 none;
	padding: 8px 0;

	& > span {
		margin-bottom: 10px;
	}

	label {
		display: flex;
		margin: 8px 0;

		input[type=checkbox] {
			margin-right: 12px;
		}

		& > span {
			display: inline-block;
		}
	}
}

.checkboxesBlock {
	@include checkboxBlockBase;
	@extend .unifiedGutter;
	@media (max-width: 665px) {
		padding: 10px;
		background: none;
		label {
			margin: 10px 0;
		}
	}
}

.checkboxHighlighted {
	@include checkboxBlockBase;
	background-color: $clr-primary-800;
	padding: 16px var(--gutter);
	margin: 8px 0 0;

	> span {
		display: inline-block;
		margin-bottom: 16px;
	}

	> label {
		margin-bottom: 8px;
	}

	@media (max-width: 665px) {
		> label {
			margin: 0;
		}
	}
}

.formTitle {
	@extend .unifiedGutter;

	font-weight: normal;
	font-size: inherit;
	margin-top: 24px;
	margin-bottom: 0;
}


.fineLines {
	font-size: 12px;
	margin: {
		top: 24px;
		bottom: 32px;
	}
	padding-inline-start: 18px;
	@extend .unifiedGutter;
}

.unifiedGutter {
	width: calc(100% - var(--gutter) * 2);
	margin: {
		left: auto;
		right: auto;
	}
}

.checkboxWithText {
	@extend .unifiedGutter;
	text-align: left;
	display: flex;
	color: $clr-neutral-100;

	margin: {
		top: 16px;
		bottom: 32px;
	}

	input[type=checkbox] {
		margin-right: 12px;
	}
}

.Breadcrumbs {
	height: 40px;
	background: $clr-primary-800;
	color: $clr-neutral-100;
	padding: 0 20px;
	padding-left: calc(20px + env(safe-area-inset-left));
	padding-right: calc(20px + env(safe-area-inset-right));
	line-height: 40px;
	font-size: 14px;
	font-weight: normal;
	cursor: default;

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;

		button[role=link] {
			color: inherit;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}

		> li:last-of-type {
			color: $clr-neutral-50;
			font-weight: bold;
		}

		> li:not(:last-of-type)::after {
			@extend %iconfont;
			width: 14px;
			height: 14px;
			margin: 0 8px;
			font-size: 10px;
			text-align: center;
			line-height: 10px;
			vertical-align: baseline;
			content: unquote("\"#{ $iconfont-angle-right }\"");
		}
	}

	@media (max-width: 665px) {
		display: none;
	}
}

.Loader {
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;
	position: relative;

	&Button {
		display: flex !important;
		justify-content: center;
		align-items: center;
		position: relative;
		pointer-events: none;
		min-width: 104px;
		width: 100% !important;
		overflow: hidden;

		.LoaderRing,
		.LoaderMiddle {
			width: 24px;
			height: 24px;
			display: block;
		}

		.LoaderRing {
			border-width: 2.5px;
			border-color: rgba($clr-exclusive-600, .2);
		}

		.LoaderMiddle {
			border-width: 2.5px;
			border-color: $clr-exclusive-600;
		}

		&:global(.button-white-outline) {
			.LoaderRing {
				border-color: rgba($clr-neutral-50, .2);
			}

			.LoaderMiddle {
				border-color: $clr-neutral-50;
			}
		}

		&Container {
			display: inline-block;
			position: relative;
			height: 42px;
		}

		&Original {
			display: inline-block;
		}

		&ShowLoader {
			.LoaderButton {
				display: flex !important;
			}

			.LoaderButtonOriginal {
				display: inline-block;
				visibility: hidden;
				height: 0;
				overflow: hidden;
			}
		}
	}

	&Ring {
		position: relative;
		width: 50px;
		height: 50px;
		border: 4px solid rgba(255, 255, 255, .2);
		border-radius: 100%;
	}

	&Middle {
		position: absolute;
		width: 50px;
		height: 50px;
		border: 4px solid #fff;
		border-radius: 100%;
		clip-path: polygon(100% 0, 100% 50%, 50% 50%, 50% 0);
	}

	&Spin {
		animation-name: spin;
		animation-duration: 1s;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}
}

.searchInput {
	display: flex;
	position: relative;
	font-size: 14px;
	flex-basis: 184px;
	flex-grow: 0;
	flex-shrink: 0;
	min-width: 0;

	input {
		display: inline-block;
		background: $clr-primary-900;
		color: $clr-neutral-50;
		appearance: none;
		border: 0;
		outline: 0;
		height: 40px;
		padding: 11px 40px 11px 8px;
		border-radius: 4px;
		min-width: 0;

		::placeholder {
			color: $clr-neutral-100;
			font-size: 12px;
		}
	}

	&::before {
		@extend %iconfont;
		content: unquote("\"#{ $iconfont-search }\"");
		position: absolute;
		width: 24px;
		height: 24px;
		right: 8px;
		top: 8px;
		border-radius: 4px;
		text-align: center;
		line-height: 24px;
		color: $clr-neutral-100;
		pointer-events: none;
		font-size: 15px;
	}


	@mixin fullWidth {
		flex-shrink: 12;
		flex-basis: 100%;

		input {
			width: 100%;
		}
	}

	&:only-child {
		@include fullWidth;
	}

	@media (max-width: 665px) {
		@include fullWidth;
	}
}

.accountFilters {
	@extend .unifiedGutter;
	display: flex;
	justify-content: center;
	align-items: stretch;
	margin: {
		top: 8px;
		bottom: 8px;
	}
	column-gap: 8px;

	@media (max-width: 665px) {
		flex-wrap: wrap;
		column-gap: 10px;
		row-gap: 8px;
	}
}

.accountSelectorBlock {
	--gutter: 98px;
	@extend .unifiedGutter;
	display: flex;
	background: $clr-primary-800;
	align-items: stretch;
	color: $clr-neutral-100;
	cursor: pointer;
	border-radius: 4px;
	position: relative;
	transition: background-color .2s linear;

	&:first-of-type {
		margin-bottom: 16px;
	}

	svg {
		display: flex;
		min-height: 100%;
		text-align: center;
		flex-shrink: 0;
		align-self: center;
		margin: 0 16px;

		> g {
			transition: fill .2s linear;
			fill: $clr-primary-300;
		}
	}

	&Inner {
		padding: {
			top: 16px;
			right: 16px;
			bottom: 16px;
		};
		line-height: 1.25;
	}

	h3 {
		display: block;
		color: $clr-neutral-50;
		font-size: 18px;
		margin: {
			top: 0;
			left: 0;
			right: 0;
			bottom: 4px;
		};
	}

	p {
		display: block;
		font-size: 14px;
		margin-right: 10px;
	}

	@media (hover: hover) {
		&:hover {
			background: $clr-primary-900;

			svg > g {
				fill: $clr-neutral-50;
			}
		}
	}

	@media (max-width: 665px) {
		--gutter: 16px;
	}
}

.accountSelectorDisabled {
	cursor: default;

	svg, .accountSelectorBlockInner {
		opacity: .4;
	}

	@media (hover: hover) {
		&:hover {
			background: $clr-primary-800;

			svg > g {
				fill: $clr-primary-300;
			}

			&::after {
				content: "";
				display: block;
				position: absolute;
				background-color: transparent;
				width: 50%;
				height: 100%;
				top: 0;
				right: -40%;
			}

			.ToolTip {
				display: block;
			}
		}
	}

	@media (hover: none) {
		&:active {
			background: $clr-primary-800;

			svg > g {
				fill: $clr-primary-300;
			}
		}
		&:focus {
			.ToolTip {
				display: block;
			}
		}
	}


}

.ToolTip {
	display: none;
	position: absolute;
	font-size: 12px;
	background-color: rgba(#000, .8);
	padding: 8px;
	border-radius: 4px;
	width: 155px;
	right: -155px - 6px;
	margin: auto 0;
	color: $clr-neutral-50;
	line-height: 1.35;
	z-index: 2;
	top: 0;
	bottom: 0;
	height: max-content;


	&::before {
		content: "";
		width: 0;
		height: 0;
		border-top: 6px solid transparent;
		border-bottom: 6px solid transparent;
		border-right: 6px solid rgba(#000, .8);
		position: absolute;
		left: -6px;
		margin: auto 0;
		top: 0;
		bottom: 0;
	}

	a {
		font-weight: bold;
	}

	&Bottom {
		@extend .ToolTip;
		right: unset;
		left: unset;
		bottom: unset;
		width: auto;
	}

	@media (max-width: 665px) {
		text-align: center;
		width: 285px;
		right: 0;
		left: 0;
		margin: 0 auto;
		top: unset;
		bottom: calc(100% + 6px);

		&::before {
			border-top: 6px solid rgba(#000, .8);
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
			border-bottom: unset;
			bottom: -6px;
			margin: 0 auto;
			top: unset;
			left: 0;
			right: 0;
		}

		&Show & {
			display: block;
		}

		&Bottom {
			top: 54px;
			right: 4px;
			left: unset;
			bottom: unset;
			width: auto;

			&::before {
				border-bottom: 6px solid rgba(#000, .8);
				border-top: unset;
				bottom: unset;
				top: -6px;
				right: 39px;
				left: unset;
			}
		}
	}
}


.transparentInfoBox {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 16px;
	padding: 16px;
	width: 640px;
	margin: 24px auto 0;
	border: 1px solid $clr-primary-500;
	border-radius: 4px;
	color: $clr-neutral-50;
	font-size: 16px;
	position: relative;

	p {
		padding-left: 32px;

		&::before {
			@extend %iconfont;
			content: unquote("\"#{ $iconfont-info }\"");
			font-size: 20px;
			position: absolute;
			top: 18px;
			left: 17px;
		}
	}

	@media (max-width: 665px) {
		font-size: 14px;
		width: auto;
		margin: 24px 16px 0;
		flex-direction: column;
		gap: 8px;
	}
}

.buttonRow {
	@extend .unifiedGutter;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 16px;
	margin-top: 32px;
	margin-bottom: 14px;

	> * {
		margin: 0 !important;
	}

	@media (max-width: 665px) {
		flex-direction: column-reverse;

	}
}

.bottomLink {
	text-align: center;
	margin: 6px 0 0;

	a {
		font-size: 14px;
		padding: 8px 16px;
		outline: none;
	}
}

.Offline {
	position: absolute;
	top: 60px;
	width: 100%;
	background-color: $clr-secondary-600;
	color: $clr-primary-800;
	font-size: 14px;
	text-align: center;
	padding: 16px 20px;
	z-index: 1;

	h3 {
		font-size: 18px;
		margin: 0;
		padding: 0;
	}
}

.productLogo {
	margin: 40px auto 0 auto;
	display: block;
	position: relative;
	text-align: center;
	pointer-events: none;
	user-select: none;

	img {
		height: 24px;
	}

	@media (max-width: 665px) {
		margin-bottom: 40px;

		img {
			height: 16px;
		}

		&::after {
			content: "";
			display: block;
			width: 42px;
			height: 1px;
			background-color: $clr-neutral-50;
			bottom: -24px;
			left: 0;
			right: 0;
			margin: 0 auto;
			position: absolute;
		}
	}
}

.accountsPage {
	h1 {
		margin-bottom: 8px;
	}
	p {
		margin-top: 8px;
	}
}