@import "styles";
@import "./styles/iconfont";

.header {
	background: linear-gradient(180deg, $clr-primary-700, $clr-primary-900);
	padding: 0 16px;
	padding-left: calc(16px + env(safe-area-inset-left));
	padding-right: calc(16px + env(safe-area-inset-right));
	min-height: 60px;
	height: 60px;
	text-align: center;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (max-width: 665px) {
		height: 48px;
		min-height: 48px;
	}
}

.headerLogo {
	height: 20px;

	img {
		pointer-events: none;
		user-select: none;
	}
}

.headerUser {
	background: $clr-primary-900;
	color: $clr-neutral-100;
	border-radius: 4px;
	height: 32px;
	white-space: nowrap;
	padding: 8px 12px;
	vertical-align: baseline;
	line-height: 16px;
	font-size: 12px;
	cursor: default;
	user-select: none;
	display: flex;
	align-items: center;
	gap: 8px;

	svg:global(.caret) {
		display: none;
	}

	@media (max-width: 665px) {
		gap: 10px;

		span {
			display: none;
		}

		svg:global(.caret) {
			display: unset;
		}
	}

}
