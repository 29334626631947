@import "styles";

.footer {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-areas: "a b c";
	font-size: 10px;
	color: $clr-neutral-50;
	line-height: 1.2em;
	column-gap: 8px;
	background-color: $clr-primary-900;
	margin: 100px 0 0;
	padding: 16px 20px;
	padding-left: calc(20px + env(safe-area-inset-left));
	padding-right: calc(20px + env(safe-area-inset-right));
	padding-bottom: calc(16px + env(safe-area-inset-bottom));

	> p,
	> ul {
		display: block;
	}

	.footerCompany {
		grid-area: a;
	}

	.footerAddress {
		grid-area: b;
	}

	.footerLinks {
		grid-area: c;
	}

	@media (max-width: 1175px) {
		margin: 50px 0 0;
		grid-template-columns: auto auto;
		grid-template-areas: "a c"
							 "b c";
		column-gap: 0;
		row-gap: 4px;

		> p,
		> ul {
			text-align: unset;
		}

		.footerLinks {
			text-align: right;
			align-self: center;
		}
	}

	@media (max-width: 665px) {
		grid-template-columns: auto;
		grid-template-areas: "a"
							 "b"
							 "c";
		text-align: center;

		.footerLinks {
			text-align: center;
			align-self: unset;
			margin-top: 12px;
		}
	}
}

.footerLinks {
	display: block;
	list-style-type: none;
	margin: 0;
	padding: 0;
	text-align: right;

	li {
		display: inline-block;
		margin: 0 8px;

		&:first-of-type {
			margin-left: 0;
		}

		&:last-of-type {
			margin-right: 0;
		}

		a,
		a:link,
		a:active,
		a:hover {
			color: $clr-neutral-50;
			display: inline-block;
		}
	}
}

.footerAddress {
	color: $clr-neutral-300;
	text-align: center;
}