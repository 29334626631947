[class^="iconfont-"]::before, [class*=" iconfont-"]::before {
  font-family: var(--font-iconfont) !important;
  font-size: 12px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  text-rendering: auto;
  display: inline-block;
}

.iconfont-angle-down:before { content: "\ea01"; }
.iconfont-angle-right:before { content: "\ea02"; }
.iconfont-angle-up:before { content: "\ea03"; }
.iconfont-caret-down:before { content: "\ea04"; }
.iconfont-caret-up:before { content: "\ea05"; }
.iconfont-checkmark:before { content: "\ea06"; }
.iconfont-info:before { content: "\ea07"; }
.iconfont-loader-progress:before { content: "\ea08"; }
.iconfont-model:before { content: "\ea09"; }
.iconfont-owner:before { content: "\ea0a"; }
.iconfont-search:before { content: "\ea0b"; }
.iconfont-user:before { content: "\ea0c"; }
.iconfont-warning:before { content: "\ea0d"; }
.iconfont-x:before { content: "\ea0e"; }
