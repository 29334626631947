@import "styles";
@import "iconfont";

.modalBackdrop {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 2;
	background: rgba($clr-primary-900, .95);
	align-items: center;
	justify-content: center;

	body:has(&[style*="display: flex"]) {
		overflow: hidden;
	}
}

.cancelModal {
	min-width: 406px;
	width: fit-content;
	background: $clr-primary-800;
	font-size: 14px;
	color: $clr-neutral-300;
	border-radius: 4px;
	text-align: center;
	cursor: default;

	> div {
		display: flex;
		gap: 8px;
		align-items: stretch;
		flex-direction: column;
		justify-content: center;
		padding: 40px;
		align-content: center;
	}

	h3, h4 {
		margin: 0;
		padding: 0;
	}

	h3 {
		display: block;
		border-radius: 4px 4px 0 0;
		background: $clr-primary-600;
		height: 36px;
		color: $clr-neutral-50;
		line-height: 36px;
		font-size: 16px;
		position: relative;

		button {
			display: block;
			position: absolute;
			top: 6px;
			right: 8px;
			width: 24px;
			height: 24px;
			text-indent: -1000%;
			white-space: nowrap;
			overflow: hidden;
			cursor: pointer;
			text-align: center;

			&::before {
				@extend %iconfont;
				content: unquote("\"#{ $iconfont-x }\"");
				color: $clr-neutral-100;
				font-size: 8px;
				position: absolute;
				top: 0;
				left: 0;
				line-height: 24px;
				width: 24px;
				height: 24px;
				text-indent: initial;
			}
		}
	}

	h4 {
		color: $clr-neutral-50;
		font-weight: normal;
	}

	@media (max-width: 665px) {
		min-width: auto;
		width: calc(100% - 20px);
		margin: 0 auto;

		& > div {
			padding: 20px;
		}
	}
}

.formCancel {
	text-align: center;
	margin: 6px 0 0;

	button {
		text-decoration: none;
		color: $clr-secondary-500;
		cursor: pointer;
		font-size: 14px;
		font-weight: bold;
		padding: 8px 16px;
		outline: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

@mixin baseConsentModal {
	cursor: default;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;
	align-content: center;
	text-align: left;
	font-size: 12px;
	z-index: 3;

	h2 {
		font-weight: bold;
		font-size: 16px;
		text-align: left;
		margin: 0;
		padding: {
			top: 0;
			left: 0;
			right: 0;
			bottom: 4px;
		};
		color: $clr-neutral-50;
	}

	& :global(.link) {
		font-size: 14px;
		font-weight: bold;
		white-space: nowrap;
	}

	@media (max-width: 665px) {
		align-self: end;
		width: 100%;
		border-radius: 20px 20px 0 0;
		padding: 32px;
		gap: 16px;
		color: $clr-neutral-50;
		font-size: 14px;

		h2 {
			font-size: 16px;
		}
	}
}

.manageCookiesModal {
	@include baseConsentModal;

	width: 406px;
	padding: 40px 24px;
	line-height: 1.2;
	color: $clr-neutral-50;
	background: $clr-primary-800;
	transition: padding .2s;
	border-radius: 4px;
	gap: 24px;
	position: fixed;
	bottom: max(env(safe-area-inset-bottom), 24px);
	right: max(env(safe-area-inset-right), 24px);
	box-shadow: $clr-neutral-400 0 0 5px;
	max-height: calc(100vh - 24px - env(safe-area-inset-top));
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;

	&:has(:global(input:checked.dialog-angle)) {
		padding: 24px;
	}

	@media (max-width: 665px) {
		transition: none;
		bottom: 0;
		right: 0;
		left: 0;
		top: 0;
		padding: 24px;
		border-radius: 0;
		justify-content: flex-start;
		max-height: initial;

		body:has(&) {
			overflow: hidden;
		}
	}

	h3, h4 {
		margin: 0;
		padding: 0;
		font-size: 14px;
		font-weight: normal;
		text-align: left;
		align-self: flex-start;
		width: 100%;

		span {
			float: right;
			clear: both;
			color: $clr-secondary-500;
			font-size: 12px;
			display: flex;
			align-items: center;
			gap: 6px;

			@media (max-width: 665px) {
				&:not(:has(:global(.checkbox-slider))) {
					display: none;
				}
			}
		}
	}

	h3 {
		padding-bottom: 8px;
		border-bottom: 1px solid $clr-primary-600;

		@media (max-width: 665px) {
			border-bottom: none;
			font-size: 16px;
			padding-bottom: 0;

			&:not(:first-of-type) {
				margin-top: 16px;
			}
		}
	}

	h4 {
		padding-bottom: 20px;
		height: 24px;
		line-height: 24px;
		box-sizing: content-box;

		@media (max-width: 665px) {
			color: $clr-primary-300;
			height: auto;
			line-height: 1.2;
			padding-bottom: 6px;
		}
	}

	div:has(h4) {
		border-bottom: 1px solid $clr-primary-600;
		width: 100%;

		&:has(h4 > span > input:global(.dialog-angle):checked) {
			border-bottom: 0;

			> :global(.dialog) {
				max-height: var(--element-height);
				padding: 8px;
			}
		}

		@media (max-width: 665px) {
			padding-bottom: 16px;

			&:last-of-type {
				border-bottom: none;
				padding-bottom: 0;
			}
		}
	}

	:global(.dialog-angle) {
		display: none;

		+ label {
			display: inline-block;
			cursor: pointer;
			top: 0;

			&:before {
				@extend %iconfont;
				display: inline-block;
				width: 24px;
				height: 24px;
				text-align: center;
				font-size: 8px;
				line-height: 24px;
				vertical-align: middle;
				color: $clr-secondary-500;
				content: unquote("\"#{ $iconfont-angle-down }\"");
				pointer-events: none;
			}

			@media (max-width: 665px) {
				display: none;
			}
		}

		&:checked + label:before {
			content: unquote("\"#{ $iconfont-angle-up }\"");
		}
	}

	:global(.dialog) {
		background: $clr-secondary-800;
		display: block;
		border-radius: 4px;
		overflow: hidden;
		box-sizing: content-box;
		max-height: 0;
		padding: {
			top: 0;
			bottom: 0;
			left: 8px;
			right: 8px;
		};
		transition: max-height .2s, padding-top .2s, padding-bottom .2s;
		clear: both;

		@media (max-width: 665px) {
			max-height: fit-content;
			background: none;
			transition: none;
			padding: {
				top: 8px !important;
				bottom: 0 !important;
				left: 0 !important;
				right: 0 !important;
			}
		}
	}
}

.consentBar {
	@include baseConsentModal;

	box-shadow: 0 4px 24px rgba($clr-primary-900, 0.25);
	background: $clr-primary-700;
	color: $clr-neutral-100;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	flex-direction: row;
	align-content: flex-start;
	align-items: center;
	line-height: 1.5;
	padding:{
		left: max(env(safe-area-inset-left), 24px);
		right: max(env(safe-area-inset-right), 24px);
		bottom: max(env(safe-area-inset-bottom), 16px);
		top: 16px;
	}
	gap: 16px;
	column-gap: 13vw;

	&Buttons {
		display: flex;
		gap: 16px;
		align-items: center;

		@media (max-width: 665px) {
			flex-direction: column;
			width: 100%;
		}
	}

	@media (max-width: 990px) {
		flex-wrap: wrap;
		text-align: left;
		justify-content: flex-start;
	}

	@media (max-width: 665px) {
		flex-direction: column;
		align-content: stretch;
		text-align: center;
		padding: {
			left: 16px;
			right: 16px;
		}
		gap: 8px;

		& :global(.link) {
			height: 42px;
		}

		h2 {
			text-align: center;
		}

		> &Text {
			padding-bottom: 16px;
		}

		p {
			width: 100%;

			button {
				width: 100%;
			}
		}
	}
}