$clr-neutral-50: #fff !default;
$clr-neutral-100: #ccc !default;
$clr-neutral-300: #999 !default;
$clr-neutral-400: #1E1E1E !default;
$clr-neutral-600: #5E5E5E !default;
$clr-secondary-400: #FFDB4D !default;
$clr-secondary-500: #FFCC00 !default;
$clr-secondary-600: #F98706 !default;
$clr-secondary-700: #8BC437 !default;
$clr-secondary-800: #2E373D !default;
$clr-primary-300: #7A86A7 !default;
$clr-primary-500: #3A4050 !default;
$clr-primary-600: #303542 !default;
$clr-primary-700: #252933 !default;
$clr-primary-800: #1E2129 !default;
$clr-primary-900: #121419 !default;
$clr-exclusive-600: #441F00 !default;

$font-family: var(--font-roboto);
